<template>
  <div class="">
    <nav-header title="修改手机号" />
    <div>
      <van-form @submit="onSubmit">
        <div class="margin-top-15">
          <van-cell-group inset>
            <van-field
                required
                v-model="form.mobile"
                label="手机号"
                placeholder="请输入手机号"
            />
            <van-field
                required
                v-model="form.code"
                label="验证码"
                placeholder="请输入验证码"
            >
              <template #button>
                <a href="javascript:" @click="getCode(form.mobile)">{{ getCodeTitle }}</a>
              </template>
            </van-field>
          </van-cell-group>
        </div>
        <div class="form-btn">
          <van-button round block type="primary" native-type="submit">
            下一步
          </van-button>
        </div>
      </van-form>
    </div>
    <no-mobile-tip/>
    <footer-lian-xike-fu/>
  </div>
</template>

<script>
import FooterLianXikeFu from "../open/footerLianXikeFu";
import NoMobileTip from "./public/noMobileTip";
import yanZhengMa from "../open/yanZhengMa";
import http from "../../api/http";
import NavHeader from "../../components/top/NavHeader";

export default {
  name: 'EditMobile',
  components: {NavHeader, NoMobileTip, FooterLianXikeFu},
  mixins: [yanZhengMa],
  data() {
    return {
      form: {}
    }
  },
  mounted() {
  },
  methods: {
    onSubmit() {
      http.post('/gzhphp/login/editMobile', this.form).then(da => {
        if (da.code === 1) {
          this.$router.push({name: 'EditMobile2'})
        }
      })
    }
  },
  watch: {},
  computed: {}
}
</script>

<style scoped>

</style>
